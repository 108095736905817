.navbar {
  padding: 40px 0 20px;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.navbar-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}
.navabar-right-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-logo-container {
  padding: 0 32px;
}

.navbar-logo {
  width: 220px;
  cursor: pointer;
}

.navbar-menu {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.ai-toolkit-container {
  display: flex;
  height: 52px;
  padding: 0px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #f5896b;
  margin-right: 30px;
  cursor: pointer;
  border: 1px solid #f5896b;
  text-decoration: none;
}

.ai-toolkit-text {
  color: #fff;
  text-align: center;
  font-family: "Mulish";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.038px;
  margin-left: 9px;
}

@media (max-width: 768px) {
  .navbar {
    padding: 30px 0px 20px;
  }

  .navbar-content {
    padding: 0 32px;
  }
  .navbar-logo-container {
    padding: 0px;
  }

  .navbar-logo {
    width: 150px;
  }
  .ai-toolkit-container {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-right: 16px;
  }
  .ai-toolkit-text {
    display: none;
  }
}

@media (min-width: 768px) {
  .ai-toolkit-text {
    width: 95%;
    opacity: 1;
    transition: width 0.15s cubic-bezier(0.01, 0.01, 1, 1);
  }
  .ai-toolkit-text.small {
    width: 0;
    opacity: 0;
  }
  .ai-toolkit-text.slide-in {
    animation: slideInFromRight 0.35s linear forwards;
    max-height: 28px;
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    50% {
      transform: translateX(50%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .ai-toolkit-container {
    transition:
      color 0.3s,
      padding 0.2s linear,
      border-color 0.3s,
      width 0.2s linear;
    position: relative;
    overflow: hidden;
  }
  .ai-toolkit-container.small {
    transition:
      color 0.3s,
      border-color 0.3s;
    position: relative;
    overflow: hidden;
    padding: 0px 8px 0px 16px;
  }
  .ai-toolkit-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: white;
    transition: height 0.3s;
  }
  .ai-toolkit-container:hover::before {
    height: 100%;
  }
  .ai-toolkit-container:hover span {
    filter: brightness(0) saturate(100%) invert(70%) sepia(63%) saturate(1211%)
      hue-rotate(313deg) brightness(97%) contrast(97%);
  }
  .ai-toolkit-container:hover img {
    filter: brightness(0) saturate(100%) invert(70%) sepia(63%) saturate(1211%)
      hue-rotate(313deg) brightness(97%) contrast(97%);
  }
}
