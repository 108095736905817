.join-team-container {
  color: #fff;
  font-family: Mulish, sans-serif;
  padding-top: 120px;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
.join-team-content {
  position: relative;
  z-index: 2;
}

.join-team-first-text {
  color: #f5896b;
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0;
  transform: translateY(40px);
}

[data-aos="animation-scale-y"] {
  -webkit-transform: translateY(80px) scaleY(1.4);
  transform: translateY(80px) scaleY(1.4);
  opacity: 0;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
}
[data-aos="animation-scale-y"].aos-animate {
  -webkit-transform: translateY(0) scaleY(1);
  transform: translateY(0) scaleY(1);
  opacity: 1;
}
.join-team-second-text {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 56px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 72.8px;
  letter-spacing: -1.12px;
  margin-top: 10px;
  opacity: 0;
  transform: translateY(40px);
}
.join-team-third-text {
  color: #fff;
  font-family: Mulish, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 20px;
  opacity: 0;
  transform: translateY(40px);
}
.join-team-background-image {
  position: absolute;
  top: 150px;
  left: 0px;
  width: 40%;
  height: 40%;
  z-index: 1;
}
@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4145.46826171875px;
    stroke-dasharray: 4145.46826171875px;
  }
  100% {
    stroke-dashoffset: 2220.9365234375px;
    stroke-dasharray: 4145.46826171875px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4145.46826171875px;
    stroke-dasharray: 4145.46826171875px;
  }
  100% {
    stroke-dashoffset: 2220.9365234375px;
    stroke-dasharray: 4145.46826171875px;
  }
}

.svg-elem-1 {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

[data-aos="animation-line"] path {
  -webkit-animation: none;
  animation: none;
}
[data-aos="animation-line"].aos-animate path {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

.contact-form-title {
  color: var(--Onboarding-background-grey, #2d2d2d);
  text-align: center;
  font-family: Author;
  font-size: 38px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 110%;
  letter-spacing: -0.76px;
  text-align: center;
  margin-top: 40px;
}

.contact-form-text {
  color: var(--Onboarding-background-grey, #2d2d2d);
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  width: 55%;
  margin-top: 10px;
}
.join-team-custom-button {
  height: 60px;
  width: 200px;
  border-radius: 20px;
  background-color: #f5896b;
  border-width: 0px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  border: 1px solid #f5896b;
  transition:
    color 0.3s,
    border-color 0.3s;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.join-team-custom-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: white;
  transition: height 0.3s;
}
.join-team-custom-button:hover::before {
  height: 100%;
}

.join-team-custom-button span {
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.join-team-custom-button span {
  position: relative;
  z-index: 1;
}
.join-team-custom-button:hover span {
  color: #f5896b;
}
.button-loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #05353a;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: calc(50% - 15px);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .join-team-container {
    padding: 0 32px;
    padding-top: 64px;
  }
  .join-team-first-text {
    font-size: 14px;
    line-height: 128.571%;
  }
  .join-team-second-text {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.4px;
    margin-top: 24px;
  }
  .join-team-third-text {
    font-size: 16px;
    line-height: 175%;
    margin-top: 40px;
    padding-bottom: 20px;
  }
  .join-team-background-image {
    display: none;
  }
  .contact-form-text {
    display: none;
  }
  .contact-form-title {
    display: none;
  }
  .join-team-custom-button {
    width: 156px;
    height: 56px;
    margin-top: 40px;
    border-radius: 8px;
    align-self: flex-start;
  }
}

@media (min-width: 768px) {
  .join-team-second-text {
    padding-left: 25%;
  }
  .join-team-first-text {
    padding-left: 25%;
  }
  .join-team-third-text {
    padding-left: 25%;
    width: 40%;
  }
  .contact-form-container {
    width: calc(80% - 80px);
    margin: 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    padding-top: 0px;
    border-radius: 20px;
    border: 1px solid var(--CTA---Aqua-Squeeze, #e5f4f2);
    background: #fff;
    margin-top: 80px;
    transform: translateY(40px);
  }
}
