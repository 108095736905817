.team-member {
  width: 100%;
  height: 400px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.team-front-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.team-back-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f5896b;
}

.team-front-content {
  backface-visibility: hidden;
}

.team-back-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.team-infoTitle {
  color: var(--color-white, #fff);
  text-align: center;
  font-family: Author;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-stroke: 0px !important;
  line-height: 22px;
  margin-top: 60px;
  width: 90%;
}

.team-infoText {
  color: var(--color-white, #fff);
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.2px;
  margin-top: 8px;
  width: 80%;
}

.team-connectWith {
  color: var(--color-white, #fff);
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 20px;
}

.team-front-image {
  width: 100%;
  height: 80%;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}

.team-front-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-front-details {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.team-icon-container {
  width: 20%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 10px;
}
.team-icon {
  width: 70px;
  height: 70px;
}

.team-info {
  width: 80%;
  padding-left: 20px;
  align-items: center;
}

.team-info p {
  margin: 5px 0;
}

.one-member-arrow-icon {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.team-back {
  background-color: #f5896b;
}

.team-name {
  color: #111827;
  font-family: "Author", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-stroke: 0px !important;
  line-height: 24px;
  text-transform: capitalize;
  margin: 10px 0;
}

.team-position {
  color: rgba(17, 24, 39, 0.8);
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  margin-bottom: 10px;
}

.team-social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.team-social-icons img {
  width: 32px;
  height: 32px;
  margin: 0 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .one-member-arrow-icon {
    top: 20px;
    right: 20px;
  }
}
