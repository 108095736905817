.journey-container {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
}
.journey-content {
  position: relative;
  z-index: 2;
}

.journey-first-text {
  margin-top: 80px;
  color: #f5896b;
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 25%;
  opacity: 0;
  transform: translateY(80px);
}

[data-aos="animation-scale-y"] {
  -webkit-transform: translateY(80px) scaleY(1.4);
  transform: translateY(80px) scaleY(1.4);
  opacity: 0;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
}
[data-aos="animation-scale-y"].aos-animate {
  -webkit-transform: translateY(0) scaleY(1);
  transform: translateY(0) scaleY(1);
  opacity: 1;
}

.journey-second-text {
  margin-top: 8px;
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 72.8px;
  letter-spacing: -1.12px;
  padding-left: 25%;
  opacity: 0;
  transform: translateY(80px);
}

.journey-video-container {
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: 2;
  width: 70%;
}

.play-icon-overlay {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.background-image {
  background-image: url("../../assets/images/VideoThumbnail.webp");
  background-size: cover;
}

.play-icon {
  background: linear-gradient(
    175deg,
    rgba(245, 137, 107, 0.3) 0%,
    #f5896b 100%
  );
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  cursor: pointer;
  animation: pulse 1.5s infinite;
  position: absolute;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(245, 137, 107, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(245, 137, 107, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(245, 137, 107, 0);
  }
}

.play-icon:hover {
  opacity: 0.8;
}
.journey-background-image {
  position: absolute;
  top: -40px;
  left: 0;
  width: 40%;
  height: 70%;
  z-index: 1;
}
@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4346.46826171875px;
    stroke-dasharray: 4346.46826171875px;
  }

  100% {
    stroke-dashoffset: 2692.9365234375px;
    stroke-dasharray: 4346.46826171875px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4346.46826171875px;
    stroke-dasharray: 4346.46826171875px;
  }

  100% {
    stroke-dashoffset: 2692.9365234375px;
    stroke-dasharray: 4346.46826171875px;
  }
}

.svg-elem-1 {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

[data-aos="animation-line"] path {
  -webkit-animation: none;
  animation: none;
}
[data-aos="animation-line"].aos-animate path {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

.play-video {
  height: 50px;
  width: 50px;
  margin-left: 10px;
}
.shapes-container {
  position: absolute;
  top: 40%;
  left: 10px;
  width: 100%;
  z-index: 1;
  pointer-events: none;
  display: flex;
  max-width: 1440px;
  gap: 35px;
}
.shapes-container-desktop-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  gap: 35px;
}
.shapes-container-desktop-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  gap: 35px;
  position: absolute;
  right: 30px !important;
}

.shapes-container svg {
  flex-shrink: 0;
}

@keyframes moveShapesX {
  0% {
    transform: scaleY(100%);
  }
  50% {
    transform: scaleY(120%);
  }
  100% {
    transform: scaleY(100%);
  }
}

.shapes-container.animate svg {
  animation: moveShapesX 1.5s linear infinite;
}

@media (min-width: 768px) {
  .shapes-container-mobile-left {
    display: none;
  }
  .shapes-container-mobile-right {
    display: none;
  }
  .shapes-container-desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    gap: 35px;
  }
}

@media (max-width: 768px) {
  .journey-container {
    overflow: visible;
  }
  .play-icon-overlay {
    display: none;
  }
  .journey-first-text {
    font-size: 14px;
    line-height: 128.571%;
    padding-left: 0%;
    padding: 0 32px;
  }
  .journey-second-text {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.4px;
    margin-top: 24px;
    padding-left: 0%;
    padding: 0 32px;
  }
  .play-icon {
    display: none;
  }
  .journey-video-container {
    width: calc(100% - 64px);
    padding: 0 32px;
  }
  .journey-background-image {
    display: none;
  }
  .play-video {
    display: none;
  }
  .shapes-container-desktop-left {
    display: none;
  }
  .shapes-container-desktop-right {
    display: none;
  }
  .shapes-container-mobile-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    gap: 6px;
  }
  .shapes-container-mobile-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    gap: 6px;
    position: absolute;
    right: 6px !important;
  }
  .shapes-container {
    top: 50%;
    left: 4px !important;
    gap: 6px !important;
  }
  @keyframes moveShapesX {
    0% {
      transform: scaleY(100%);
    }
    50% {
      transform: scaleY(120%);
    }
    100% {
      transform: scaleY(100%);
    }
  }
  .shapes-container.animate svg {
    animation: moveShapesX 1.5s linear infinite;
  }
}
@media (min-width: 768px) and (max-width: 1350px) {
  .shapes-container-mobile-left {
    display: none;
  }
  .shapes-container-mobile-right {
    display: none;
  }
  .shapes-container-desktop-right {
    gap: 15px;
  }
  .shapes-container-desktop-left {
    gap: 15px;
  }
  .shapes-container svg {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  @keyframes moveShapesX {
    0% {
      transform: scaleY(100%) scale(0.8);
    }
    50% {
      transform: scaleY(120%) scale(0.8);
    }
    100% {
      transform: scaleY(100%) scale(0.8);
    }
  }
  .shapes-container.animate svg {
    animation: moveShapesX 1.5s linear infinite;
  }
}
