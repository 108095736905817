.services-container {
  color: #fff;
  font-family: Mulish, sans-serif;
  padding-top: 120px !important;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
.services-content {
  position: relative;
  z-index: 2;
}

.services-first-text {
  color: #f5896b;
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0;
  transform: translateY(80px);
}

[data-aos="animation-scale-y"] {
  -webkit-transform: translateY(80px) scaleY(1.4);
  transform: translateY(80px) scaleY(1.4);
  opacity: 0;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
}
[data-aos="animation-scale-y"].aos-animate {
  -webkit-transform: translateY(0) scaleY(1);
  transform: translateY(0) scaleY(1);
  opacity: 1;
}
.services-second-text {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 56px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 72.8px;
  letter-spacing: -1.12px;
  margin-top: 10px;
  opacity: 0;
  transform: translateY(80px);
}
.services-third-text {
  color: #fff;
  font-family: Mulish, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 20px;
  opacity: 0;
  transform: translateY(80px);
}
.services-background-image {
  position: absolute;
  top: 40px;
  left: 30%;
  width: 50%;
  height: 70%;
  z-index: 1;
}
@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4345.46826171875px;
    stroke-dasharray: 4345.46826171875px;
  }
  100% {
    stroke-dashoffset: 2090.9365234375px;
    stroke-dasharray: 4345.46826171875px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4345.46826171875px;
    stroke-dasharray: 4345.46826171875px;
  }
  100% {
    stroke-dashoffset: 2090.9365234375px;
    stroke-dasharray: 4345.46826171875px;
  }
}

.svg-elem-1 {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

[data-aos="animation-line"] path {
  -webkit-animation: none;
  animation: none;
}
[data-aos="animation-line"].aos-animate path {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

.services-content-container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .services-container {
    padding: 0 32px;
    padding-top: 64px;
  }
  .services-first-text {
    font-size: 14px;
    line-height: 128.571%;
  }
  .services-second-text {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.4px;
    margin-top: 24px;
  }
  .services-third-text {
    font-size: 16px;
    line-height: 175%;
    margin-top: 40px;
    padding-bottom: 20px;
  }
  .services-background-image {
    display: none;
  }
}

@media (min-width: 768px) {
  .services-second-text {
    padding-left: 25%;
  }
  .services-first-text {
    padding-left: 25%;
  }
  .services-third-text {
    padding-left: 25%;
    width: 30%;
  }
}
