.footer-container {
  position: relative;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-family: "Author";
  font-size: 27.5px;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 160%;
  padding: 120px 10% 40px;
  max-width: 1440px;
  margin: 0 auto;
}

.footer-title-one-texts {
  color: #ccc;
  font-family: "Mulish";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 20px;
}

.footer-title-one-text {
  width: 100%;
  color: #ccc;
  text-decoration: none;
}
.footer-title {
  color: #fff;
  font-family: "Author";
  font-size: 27.5px;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 160%;
}

.footer-icon-row {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: -30px;
}

.footer-icon-row img {
  width: 32px;
  cursor: pointer;
  margin-left: 20px;
}
.footer-social-media-icon:hover {
  filter: brightness(0) saturate(100%) invert(70%) sepia(63%) saturate(1211%)
    hue-rotate(313deg) brightness(97%) contrast(97%);
}
.footer-link {
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.footer-links {
  display: flex;
  justify-content: space-between;
}
.footer-links-column p {
  color: #ccc;
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 0px;
  cursor: pointer;
}
.footer-link-text:hover {
  color: #f5896b;
}
.footer-links-second-column {
  padding-left: 20px;
}
.footer-horizontal-line {
  width: 90%;
  background-color: #858584;
  height: 1.25px;
  margin-top: 0px;
  margin-left: 5%;
  max-width: 1440px;
  margin: 0 auto;
}
.footer-copyright {
  color: #ccc;
  font-family: "Mulish";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  padding-bottom: 30px;
  margin-bottom: 0px;
  max-width: 1440px;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 100px 30px 40px;
  }
  .footer-title-one-texts {
    font-size: 16px;
  }
  .footer-icon-row img {
    width: 24px;
  }
  .footer-links-column p {
    font-size: 16px;
    margin-top: 10px;
  }
  .footer-horizontal-line {
    margin-top: -20px;
    width: calc(100% - 60px);
    margin-left: 30px;
  }
  .footer-copyright {
    font-size: 12px;
    margin-top: 20px;
    margin-left: 30px;
    padding-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .footer-container {
    font-size: 23px;
  }
  .footer-links-column p {
    font-size: 16px;
  }
  .footer-title-one-texts {
    font-size: 16px;
  }
}
@media (min-width: 1100px) and (max-width: 1450px) {
  .footer-copyright {
    margin-left: 5% !important;
  }
}
@media (min-width: 1450px) {
  .footer-copyright {
    margin: 0 auto;
    margin-top: 25px;
  }
}
