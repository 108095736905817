.tailored-programs-container {
  color: #fff;
  font-family: Mulish, sans-serif;
  padding-top: 120px !important;
  position: relative;
  padding-bottom: 80px;
  max-width: 1440px;
  margin: 0 auto;
}
.tailored-programs-content {
  position: relative;
  z-index: 2;
}

.tailored-programs-first-text {
  color: #f5896b;
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0;
  transform: translateY(80px);
}

[data-aos="animation-scale-y"] {
  -webkit-transform: translateY(80px) scaleY(1.4);
  transform: translateY(80px) scaleY(1.4);
  opacity: 0;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
}
[data-aos="animation-scale-y"].aos-animate {
  -webkit-transform: translateY(0) scaleY(1);
  transform: translateY(0) scaleY(1);
  opacity: 1;
}
.tailored-programs-second-text {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 56px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 72.8px;
  letter-spacing: -1.12px;
  margin-top: 10px;
  opacity: 0;
  transform: translateY(80px);
}
.tailored-programs-background-image {
  position: absolute;
  left: 0px;
  top: 50px;
  width: 50%;
  height: 30%;
  z-index: 1;
}
@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4345.46826171875px;
    stroke-dasharray: 4345.46826171875px;
  }

  100% {
    stroke-dashoffset: 2490.9365234375px;
    stroke-dasharray: 4345.46826171875px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4345.46826171875px;
    stroke-dasharray: 4345.46826171875px;
  }

  100% {
    stroke-dashoffset: 2490.9365234375px;
    stroke-dasharray: 4345.46826171875px;
  }
}

.svg-elem-1 {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

[data-aos="animation-line"] path {
  -webkit-animation: none;
  animation: none;
}
[data-aos="animation-line"].aos-animate path {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

.tailored-programs-categories {
  background-color: #ffffff;
  width: 70%;
  margin: 0 auto;
  margin-top: 100px;
  box-sizing: border-box;
  border-radius: 20px;
}

.tailored-programs-categories-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tailored-programs-category-background {
  width: 128px;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1ecfd;
  border-radius: 50%;
  flex-shrink: 0;
}

.tailored-programs-category-icon {
  width: 98px;
  height: 98px;
}

.tailored-programs-category-title {
  color: #2d2d2d;
  text-align: center;
  font-family: "Author", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 24px;
}
.tailored-programs-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  padding: 30px;
  cursor: pointer;
}
.tailored-programs-category.pressed {
  background: #f5896b;
  border-radius: 48px;
  z-index: 1;
  margin-top: -40px;
  margin-bottom: -40px;
  padding-top: 70px;
}

.tailored-programs-category.pressed .tailored-programs-category-title {
  color: #fff;
}
.tailored-programs-categories-container {
  margin-top: 60px;
  margin-bottom: 60px;
  overflow: visible;
  transform: translateY(80px);
}
.tailored-programs-categories-container-mobile {
  display: flex;
  margin-top: 48px;
  width: calc(100% - 64px);
  justify-content: space-between;
  align-items: center;
  transform: translateY(80px);
}

.tailored-programs-category-mobile.pressed {
  border-radius: 1000px;
  padding: 12px 20px;
  background: #f5896b;
  color: #fff;
}

.tailored-programs-category-title-mobile {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 150%;
}
.tailored-programs-categories-line-mobile {
  width: calc(100% + 60px);
  margin-top: 24px;
  height: 1px;
  margin-left: -30px;
  background-color: #fff;
}
.tailored-programs-additional-icon-and-text {
  display: flex;
  text-decoration: none;
  cursor: pointer;
}

.tailored-programs-additional-text {
  color: #f5896b;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 24px;
  margin-left: 9px;
  margin-top: 40px;
}
.tailored-programs-additional-icon-and-text-desktop {
  display: flex;
  margin-top: 20px;
  text-decoration: none;
  cursor: default;
}
.tailored-programs-additional-text-desktop-container {
  text-decoration: none;
}

.tailored-programs-additional-text-desktop {
  color: #f5896b;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 24px;
  margin-left: 9px;
  margin-top: 40px;
  cursor: pointer;
}
.tailored-programs-main-content-mobile {
  padding-top: 0px;
  transform: translateY(80px);
}
.tailored-programs-category-item-title {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 32px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: left;
}
.tailored-programs-category-item-subtitle {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 16px;
  text-align: left;
}
.tailored-programs-items-scroll-mobile {
  display: flex;
  overflow-x: scroll;
  margin-top: 40px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  transform: translateY(80px);
}

.tailored-programs-items-scroll-mobile::-webkit-scrollbar {
  display: none;
}

.tailored-programs-item-mobile {
  flex: 0 0 60%;
  border-radius: 32px;
  background: #f5896b;
  padding: 16px 24px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.tailored-programs-item-mobile-text {
  color: #fff;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 10px;
}

.tailored-programs-item-mobile:last-child {
  margin-right: 0;
}
.tailored-programs-item-mobile-icon-container {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #fff;
  margin-top: 10px;
}
.tailored-programs-item-mobile-tag {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: normal;
}
.tailored-programs-main-content-desktop {
  position: relative;
  flex: 0 0 100%;
  margin-left: 10%;
  margin-right: 5%;
  padding: 48px 132px;
  border-radius: 40px;
  margin-top: 100px;
  background: #fff;
  z-index: 2;
  transform: translateY(80px);
}
.tailored-programs-container-behind {
  position: absolute;
  top: 70px;
  left: -40px;
  width: 40px;
  height: calc(100% - 140px);
  border-radius: 40px 0 0 40px;
  background: #f5896b;
  z-index: 1;
}

.tailored-programs-main-content-desktop-title {
  color: #000;
  font-family: "Author", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 68px;
  letter-spacing: -0.5px;
  text-align: center;
  margin-top: 0px;
}

.tailored-programs-main-content-desktop-subtitle {
  color: var(--Onboarding-background-grey, #2d2d2d);
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: -20px;
}
.tailored-programs-items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-top: 40px;
}
.tailored-programs-item-row {
  display: flex;
  width: calc(50% - 10px);
}
.tailored-programs-item-container {
  flex: 1;
  max-width: calc(100% - 10px);
}
.tailored-programs-item-content {
  padding: 0px 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  border: 1px solid #d1ecfd;
  background-color: "#FFF";
  align-items: center;
}
.tailored-programs-item-content-icon-container {
  background-color: #d1ecfd;
  border-radius: 12px;
  width: 50px;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
}
.tailored-programs-item-content-icon {
  width: 50px;
  height: 30px;
}
.tailored-programs-item-content-text {
  color: #000;
  text-align: left;
  font-family: "Mulish", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .tailored-programs-arrow-icons-container {
    padding-top: 10px;
    background-color: #05353a !important;
    padding-left: 20px;
  }
  .tailored-programs-arrow-icon {
    width: 64px;
    height: 64px;
    cursor: pointer;
  }
  .tailored-programs-container {
    padding: 0;
    padding-bottom: 64px;
  }
  .tailored-programs-first-text {
    font-size: 14px;
    line-height: 128.571%;
    padding-left: 32px;
  }
  .tailored-programs-second-text {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.4px;
    margin-top: 24px;
    padding-left: 32px;
  }
  .tailored-programs-items-scroll-mobile {
    padding-left: 32px;
    padding-right: 32px;
  }
  .tailored-programs-categories-container-mobile {
    padding-left: 32px;
  }
  .tailored-programs-main-content-mobile {
    padding: 0 32px;
  }
  .tailored-programs-additional-icon-and-text {
    padding-left: 32px;
  }
  .tailored-programs-background-image {
    display: none;
  }
  .tailored-programs-categories-container {
    display: none;
  }
  .tailored-programs-main-content-desktop {
    display: none;
  }
  .tailored-programs-additional-icon-and-text-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .tailored-programs-arrow-icons-container {
    display: none;
  }
  .tailored-programs-second-text {
    padding-left: 25%;
  }
  .tailored-programs-first-text {
    padding-left: 25%;
  }
  .tailored-programs-categories-container-mobile {
    display: none;
  }
  .tailored-programs-categories-line-mobile {
    display: none;
  }
  .tailored-programs-additional-icon-and-text {
    display: none;
  }
  .tailored-programs-main-content-mobile {
    display: none;
  }
  .tailored-programs-items-scroll-mobile {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .tailored-programs-category-title {
    font-size: 26px;
  }
  .tailored-programs-category-background {
    width: 98px;
    height: 98px;
  }

  .tailored-programs-category-icon {
    width: 78px;
    height: 78px;
  }
  .tailored-programs-categories-container-mobile {
    display: none;
  }
  .tailored-programs-categories-line-mobile {
    display: none;
  }
  .tailored-programs-additional-icon-and-text {
    display: none;
  }
  .tailored-programs-main-content-mobile {
    display: none;
  }
  .tailored-programs-items-scroll-mobile {
    display: none;
  }
  .tailored-programs-main-content-desktop {
    padding: 36px 60px;
  }
  .tailored-programs-main-content-desktop-title {
    font-size: 48px;
    line-height: 56px;
  }
  .tailored-programs-main-content-desktop-subtitle {
    font-size: 16px;
  }
  .tailored-programs-item-content-text {
    font-size: 16px;
  }
}
