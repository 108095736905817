body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-stroke: 0px !important;
}

.app-wrapper {
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-stroke: 0px !important;
}

@font-face {
  font-family: "Mulish";
  src: url("../src/assets/fonts/Mulish/Mulish-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mulish-Bold";
  src: url("../src/assets/fonts/Mulish/Mulish-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Author";
  src: url("../src/assets/fonts/Author/Author-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Author";
  src: url("../src/assets/fonts/Author/Author-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Author";
  src: url("../src/assets/fonts/Author/Author-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "Author";
  src: url("../src/assets/fonts/Author/Author-Medium.ttf") format("truetype");
}
