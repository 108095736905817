.stories-container {
  color: #fff;
  font-family: Mulish, sans-serif;
  padding-top: 120px;
  padding-bottom: 10px;
  position: relative;
  max-width: 1440px !important;
  overflow-x: hidden;
  margin: 0 auto;
}
.stories-content-container {
  position: relative;
  z-index: 2;
}

.stories-first-text {
  color: #f5896b;
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0;
  transform: translateY(80px);
}

[data-aos="animation-scale-y"] {
  -webkit-transform: translateY(80px) scaleY(1.4);
  transform: translateY(80px) scaleY(1.4);
  opacity: 0;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
}
[data-aos="animation-scale-y"].aos-animate {
  -webkit-transform: translateY(0) scaleY(1);
  transform: translateY(0) scaleY(1);
  opacity: 1;
}
.stories-second-text {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 56px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 72.8px;
  letter-spacing: -1.12px;
  margin-top: 10px;
  opacity: 0;
  transform: translateY(80px);
}
.stories-third-text {
  color: #fff;
  font-family: Mulish, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 20px;
  opacity: 0;
  transform: translateY(80px);
}
.stories-desktop-view {
  display: flex;
  padding-left: 13%;
  overflow-x: scroll;
  margin-top: 70px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.stories-desktop-view::-webkit-scrollbar {
  display: none;
}
.stories-show-more {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  max-width: 300px;
}
.stories-show-more-text {
  color: #f5896b;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 24px;
  margin-left: 9px;
}
.stories-background-image-one {
  position: absolute;
  left: 0%;
  bottom: 340px;
  width: 40%;
  height: 40%;
  z-index: 1;
}
@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4545.46826171875px;
    stroke-dasharray: 4545.46826171875px;
  }
  100% {
    stroke-dashoffset: 2630.9365234575px;
    stroke-dasharray: 4545.46826171875px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4545.46826171875px;
    stroke-dasharray: 4545.46826171875px;
  }
  100% {
    stroke-dashoffset: 2630.9365234575px;
    stroke-dasharray: 4545.46826171875px;
  }
}

.svg-elem-1 {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

[data-aos="animation-line"] path {
  -webkit-animation: none;
  animation: none;
}
[data-aos="animation-line"].aos-animate path {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

.stories-background-image-two {
  position: absolute;
  top: 35%;
  left: 50%;
  width: 60%;
  height: 20%;
  z-index: 1;
}
.stories-world-image-container {
  margin-top: 50px;
  position: relative;
  margin-left: 25%;
  display: flex;
  width: 50%;
  height: 350px;
}
.stories-world-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.stories-world-image-border {
  position: absolute;
  width: 100%;
  height: 80%;
  left: 0;
  top: 20%;
  z-index: 3;
}
.stories-world-image-container:hover {
  cursor: url("../../assets/icons/StoriesFounder.png"), auto;
}
.stories-horizontal-line {
  width: 100%;
  height: 1px;
  background-color: #255B61;
  margin-bottom: 24px;
}
.stories-moving-texts-container {
  margin-bottom: 24px;
  flex-direction: row;
  align-items: center;
  display: flex;
  white-space: nowrap;
  animation: moveText 1800s linear infinite;
}
.stories-moving-text {
  display: inline-block;
  line-height: 40px; 
  font-size: 40px;
  font-family: Mulish, sans-serif;
  font-weight: 800;
  padding-left: 18px;
  padding-right: 18px;
  color: #F5896B;
}
@keyframes moveText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-10000%);
  }
}
.stories-moving-star {
  margin-bottom: 2px;
}
.stories-developer-icon {
  position: absolute;
  left: -40px;
  bottom: 70px;
  z-index: 5;
  animation: moveDeveloper 20s linear infinite;
  width: 100px;
  height: 50px;
}
@keyframes moveDeveloper {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  10% {
    transform: translateX(60px) translateY(0px);
  }
  20% {
    transform: translateX(100px) translateY(-40px);
  }
  30% {
    transform: translateX(120px) translateY(-20px);
  }
  40% {
    transform: translateX(240px) translateY(-120px);
  }
  50% {
    transform: translateX(300px) translateY(-150px);
  }
  60% {
    transform: translateX(300px) translateY(-20px);
  }
  70% {
    transform: translateX(200px) translateY(-80px);
  }
  80% {
    transform: translateX(120px) translateY(-60px);
  }
  90% {
    transform: translateX(50px) translateY(-10px);
  }
  100% {
    transform: translateX(0px) translateY(0px);
  }
}
.stories-designer-icon {
  position: absolute;
  right: 20px;
  top: 50px;
  z-index: 5;
  animation: moveDesigner 20s linear infinite;
  width: 100px;
  height: 50px;
}
@keyframes moveDesigner {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  10% {
    transform: translateX(-20px) translateY(50px);
  }
  20% {
    transform: translateX(-90px) translateY(60px);
  }
  30% {
    transform: translateX(-140px) translateY(30px);
  }
  40% {
    transform: translateX(-200px) translateY(90px);
  }
  50% {
    transform: translateX(-320px) translateY(160px);
  }
  60% {
    transform: translateX(-300px) translateY(20px);
  }
  70% {
    transform: translateX(-170px) translateY(90px);
  }
  80% {
    transform: translateX(-120px) translateY(30px);
  }
  90% {
    transform: translateX(-90px) translateY(40px);
  }
  100% {
    transform: translateX(0px) translateY(0px);
  }
}
.stories-founder-icon {
  display: none;
}

@media (max-width: 768px) {
  .stories-container {
    padding-top: 64px;
  }
  .stories-first-text {
    font-size: 14px;
    line-height: 128.571%;
    padding: 0 32px;
  }
  .stories-second-text {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.4px;
    margin-top: 24px;
    padding: 0 32px;
  }
  .stories-third-text {
    font-size: 16px;
    line-height: 175%;
    margin-top: 40px;
    padding-bottom: 20px;
    padding: 0 32px;
  }
  .stories-desktop-view {
    display: none;
  }
  .stories-background-image-one {
    display: none;
  }
  .stories-background-image-two {
    display: none;
  }
  .stories-show-more {
    padding: 0 32px;
  }
  .stories-show-more-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .stories-moving-texts-container {
    animation: moveText 900s linear infinite;
    margin-bottom: 16px;
  }
  .stories-moving-text {
    font-size: 30px;
    line-height: 30px;
  }
  .stories-world-image-container {
    height: 170px;
    width: 80%;
    margin-left: 10%;
    margin-top: 40px;
  }
  .stories-moving-star {
    margin-bottom: 0px;
    width: 16px;
    height: 16px;
  }
  .stories-horizontal-line {
    margin-bottom: 16px;
  }
  .stories-designer-icon {
    right: 0px;
    top: 100px;
    animation: none;
  }
  .stories-developer-icon {
    left: -40px;
    bottom: 20px;
    animation: none;
  }
  .stories-founder-icon {
    position: absolute;
    display: flex;
    right: 70px;
    top: 40px;
    z-index: 5;
    width: 100px;
    height: 50px;
  }
}

@media (min-width: 768px) {
  .stories-second-text {
    padding-left: 25%;
  }
  .stories-first-text {
    padding-left: 25%;
  }
  .stories-third-text {
    padding-left: 25%;
    width: 50%;
  }
  .stories-show-more {
    padding-left: 25%;
    margin-top: 40px;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .stories-world-image-container {
    width: 60%;
    margin-left: 20%;
    height: 300px;
  }
}
