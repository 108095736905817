.one-service-item {
  width: calc(20% - 20px);
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #000;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  transform: translateY(80px);
}

[data-aos="animation-scale-y"] {
  -webkit-transform: translateY(80px) scaleY(1.2);
  transform: translateY(80px) scaleY(1.2);
  opacity: 0;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
}
[data-aos="animation-scale-y"].aos-animate {
  -webkit-transform: translateY(0) scaleY(1);
  transform: translateY(0) scaleY(1);
  opacity: 1;
}

.one-service-item:nth-child(3n + 3) {
  margin-right: 0;
}

.one-service-title {
  color: #2d2d2d;
  text-align: center;
  font-family: "Author";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.4px;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-stroke: 0px !important;
  line-height: 30.8px;
  margin-top: 10px;
  padding-right: 10px;
}

.one-service-text {
  color: #000;
  text-align: center;
  font-family: "Mulish";
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  padding-bottom: 25px;
  padding-right: 5px;
}

@media (min-width: 768px) {
  .one-service-item {
    width: calc(30% - 20px);
    margin: 15px;
    box-sizing: border-box;
    display: inline-block;
    height: 100%;
  }
  .one-service-item:hover {
    background-color: #f5896b;
    color: #fff;
  }
  .one-service-item:hover .one-service-title,
  .one-service-item:hover .one-service-text {
    color: #fff;
    -webkit-font-smoothing: antialiased;
  }

  .one-service-item:nth-child(3n + 1) {
    clear: left;
  }

  .p-0.m-0 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1140px;
  }
  .one-service-icon {
    height: 150px;
    width: 150px;
  }
}

@media (max-width: 768px) {
  .one-service-item {
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-radius: 20px;
  }

  .one-service-icon {
    min-width: 120px;
    min-height: 120px;
    margin: 0px;
    flex: 0 0 40%;
  }

  .one-service-content {
    flex: 0 0 60%;
    padding-left: 10px;
    text-align: left;
  }

  .one-service-title {
    color: #2d2d2d;
    font-size: 16px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased !important;
    -webkit-text-stroke: 0px !important;
    line-height: 22.4px;
    margin-bottom: 10px;
    padding-top: 10px;
    text-align: left !important;
  }

  .one-service-text {
    color: #2d2d2d;
    font-family: "Mulish", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    text-align: left !important;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .one-service-item:nth-child(5) .one-service-text {
    padding-bottom: 0;
  }
}
