.medium-container {
  color: #fff;
  font-family: Mulish, sans-serif;
  padding-top: 120px !important;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
.medium-content {
  position: relative;
  z-index: 2;
}

.medium-first-text {
  color: #f5896b;
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0;
  transform: translateY(40px);
}

[data-aos="animation-scale-y"] {
  -webkit-transform: translateY(80px) scaleY(1.4);
  transform: translateY(80px) scaleY(1.4);
  opacity: 0;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
}
[data-aos="animation-scale-y"].aos-animate {
  -webkit-transform: translateY(0) scaleY(1);
  transform: translateY(0) scaleY(1);
  opacity: 1;
}
.medium-second-text {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 56px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 72.8px;
  letter-spacing: -1.12px;
  margin-top: 10px;
  opacity: 0;
  transform: translateY(40px);
}
.medium-third-text {
  color: #fff;
  font-family: Mulish, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 20px;
  opacity: 0;
  transform: translateY(40px);
}
.medium-mobile-button {
  width: 210px;
  height: 56px;
  margin-top: 40px;
  border-radius: 8px;
  background: #f5896b;
  color: #fff;
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(40px);
}

.medium-desktop-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 10%;
}
.medium-desktop-button {
  display: flex;
  align-items: center;
  margin-top: 64px;
  width: 20%;
  padding: 18.025px 56.328px;
  justify-content: center;
  gap: 9.013px;
  border-radius: 22.531px;
  background: #f5896b;
  color: #fff;
  text-decoration: none;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  border: 1px solid #f5896b;
  overflow: hidden;
  position: relative;
  transform: translateY(40px);
}

.medium-desktop-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: white;
  transition: height 0.3s;
}

.medium-desktop-button:hover::before {
  height: 100%;
}
.medium-desktop-button:hover span {
  filter: brightness(0) saturate(100%) invert(70%) sepia(63%) saturate(1211%)
    hue-rotate(313deg) brightness(97%) contrast(97%);
}

.medium-desktop-button:hover img {
  filter: brightness(0) saturate(100%) invert(70%) sepia(63%) saturate(1211%)
    hue-rotate(313deg) brightness(97%) contrast(97%);
}

.medium-mobile-images-container {
  display: flex;
  flex-direction: column;
}

.medium-image {
  width: 100%;
  height: 250px;
  border-radius: 20px;
  margin-top: 40px;
}
.medium-image-text-1,
.medium-image-text-2 {
  text-align: left;
  width: 100%;
  margin: 0;
}

.medium-image-text-1 {
  color: #fff;
  font-family: Author;
  font-size: 24px;
  font-weight: 494;
  line-height: 36px;
  margin-top: 10px;
}

.medium-image-text-2 {
  color: #fff;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 15px;
  opacity: 0.6;
}
.medium-desktop-images-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
  padding: 0 10%;
}

.medium-desktop-image {
  width: 100%;
  height: 400px;
}
.medium-desktop-image-container {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #fff;
  margin-right: -10px;
  width: 100%;
  margin-top: -40px;
}

.medium-desktop-image-text {
  text-align: left;
}
.medium-image-text-1-desktop {
  color: #111029;
  font-family: Author;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.15px;
  width: 80%;
  margin-bottom: 5px;
  margin-left: 40px;
  padding-top: 20px !important;
}

.medium-image-text-2-desktop {
  color: #6b6b6b;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 40px;
  padding-bottom: 20px;
}
.medium-background-image {
  position: absolute;
  top: 150px;
  left: 0px;
  width: 40%;
  height: 70%;
  z-index: 1;
}
@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4145.46826171875px;
    stroke-dasharray: 4145.46826171875px;
  }
  100% {
    stroke-dashoffset: 2220.9365234375px;
    stroke-dasharray: 4145.46826171875px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4145.46826171875px;
    stroke-dasharray: 4145.46826171875px;
  }
  100% {
    stroke-dashoffset: 2220.9365234375px;
    stroke-dasharray: 4145.46826171875px;
  }
}

.svg-elem-1 {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

[data-aos="animation-line"] path {
  -webkit-animation: none;
  animation: none;
}
[data-aos="animation-line"].aos-animate path {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

.medium-one-article-container {
  cursor: pointer;
  transform: translateY(40px);
}

@media (max-width: 768px) {
  .medium-container {
    padding: 0 32px;
    padding-top: 64px;
  }
  .medium-first-text {
    font-size: 14px;
    line-height: 128.571%;
  }
  .medium-second-text {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.4px;
    margin-top: 24px;
  }
  .medium-third-text {
    font-size: 16px;
    line-height: 175%;
    margin-top: 40px;
    padding-bottom: 20px;
  }
  .medium-desktop-button {
    display: none;
  }
  .medium-desktop-container {
    display: none;
  }
  .medium-desktop-images-container {
    display: none;
  }
  .medium-background-image {
    display: none;
  }
}

@media (min-width: 768px) {
  .medium-second-text {
    padding-left: 25%;
  }
  .medium-first-text {
    padding-left: 25%;
  }
  .medium-third-text {
    padding-left: 25%;
    width: 35%;
  }
  .medium-mobile-button {
    display: none;
  }
  .medium-mobile-images-container {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .medium-desktop-button {
    width: 30%;
    font-size: 14px;
  }
}
