.hero-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 200px 0 20px;
  text-align: center;
  background: linear-gradient(
    180deg,
    #17c1c1 7.49%,
    #154b4b 38.19%,
    #05353a 54.43%
  );
  height: 1300px;
}
.hero-content {
  max-width: 1440px;
  margin: 0 auto;
  z-index: 2;
  top: 200px;
  transition: all 0.000000001s ease;
  -webkit-transition: all 0.000000001s ease;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.hero-logo {
  width: 40%;
  height: auto;
  min-width: 300px;
  transition: transform 0.000000001s ease;
  -webkit-transition: transform 0.000000001s ease;
  backface-visibility: hidden !important;
  -webkit-backface-visibility: hidden !important;
}

.text-container {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.hero-text {
  font-family: "Author", sans-serif;
  font-size: 124px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 124px;
  letter-spacing: -0.025em;
  text-align: center;
  color: #fff;
  transition: transform 0.000000001s ease;
}
.hero-background-image {
  position: absolute;
  top: 80%;
  right: 0px !important;
  left: -10px !important;
  width: 100% !important;
  height: 30%;
  z-index: 1;
}
@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 2445.46826171875px;
    stroke-dasharray: 2445.46826171875px;
  }
  100% {
    stroke-dashoffset: 1420.9365234375px;
    stroke-dasharray: 2445.46826171875px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 2445.46826171875px;
    stroke-dasharray: 2445.46826171875px;
  }
  100% {
    stroke-dashoffset: 1420.9365234375px;
    stroke-dasharray: 2445.46826171875px;
  }
}

.svg-elem-1 {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

[data-aos="animation-line"] path {
  -webkit-animation: none;
  animation: none;
}
[data-aos="animation-line"].aos-animate path {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

.hero-scroll-container {
  margin-top: 50px !important;
  margin-left: 120px !important;
  width: 20% !important;
  align-self: flex-start !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  backface-visibility: hidden !important;
  -webkit-backface-visibility: hidden !important;
}
.hero-scroll-text {
  color: #fff;
  font-family: Author;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 16px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .hero-section {
    padding: 0px 30px 20px;
    justify-content: flex-start !important;
    height: 1500px;
  }
  .hero-content {
    margin: 0 !important;
    top: 0 !important;
  }
  .hero-logo {
    position: relative;
  }

  .text-container {
    position: absolute;
    text-align: center;
  }

  .hero-text {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.5px;
    text-align: center;
    padding-right: 20px;
  }
  .hero-scroll-container {
    display: none;
  }
}

@media (min-width: 768px) {
  .hero-background-image {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .hero-text {
    font-size: 80px;
  }
}
