.menu-container {
  display: grid;
  padding: 0px;
  margin: 0px;
  height: 100dvh;
}
.menu-content {
  z-index: 2;
  display: grid;
  grid-template-columns: 25% 75%;
  width: 100% !important;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}
.menu-background-image {
  position: absolute;
  right: 0px;
  bottom: 10%;
  width: 60%;
  height: 50%;
  z-index: 1;
}
.menu-left-container {
  background: linear-gradient(
    180deg,
    #17c1c1 7.49%,
    #154b4b 38.19%,
    #05353a 54.43%
  );
}

.menu-right-container {
  background-color: #fff !important;
  position: relative;
  padding: 60px;
}
.top-right-component {
  display: flex;
  padding-top: 20px;
}

.top-right-left,
.top-right-right {
  width: 50%;
  padding: 20px;
}

.top-right-left {
  display: flex;
  flex-direction: column;
  padding-top: 20%;
}

.top-right-left-info-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 50px;
}

.top-right-left-info-icon {
  width: 24px;
  margin-right: 10px;
}

.top-right-left-info-text {
  color: var(--Text-Dark, #111029);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-left: 40px;
  text-decoration: none;
}

.subtitle-item {
  color: var(--Text-Dark, #111029);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Author;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
  letter-spacing: -0.5px;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  transform: translateY(20px);
}
[data-aos="animation-scale-y"] {
  -webkit-transform: translateY(20px) scaleY(1.4);
  transform: translateY(20px) scaleY(1.4);
  opacity: 0;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
}
[data-aos="animation-scale-y"].aos-animate {
  -webkit-transform: translateY(0) scaleY(1);
  transform: translateY(0) scaleY(1);
  opacity: 1;
}

.subtitle-item:hover {
  color: #f5896b;
}
.social-media-item {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  color: var(--Text-Dark, #111029);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-top: 20px;
}
.social-media-item:hover {
  filter: brightness(0) saturate(100%) invert(70%) sepia(63%) saturate(1211%)
    hue-rotate(313deg) brightness(97%) contrast(97%);
}

.social-media-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.social-media-text {
  margin-left: 5px;
}

.bottom-right-component {
  padding-top: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.menu-logo {
  padding-left: 23%;
  padding-top: 40px;
  width: 60%;
}
.menu-biglogo {
  padding-top: 50%;
  width: 80%;
  padding-left: 10%;
}
.close-icon-container-desktop {
  position: absolute;
  right: 35px;
  top: 25px;
  z-index: 9999;
  cursor: pointer;
}
.close-icon-desktop {
  width: 64px;
  height: 64px;
}
.menu-mobile-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between !important;
  padding: 30px 0;
  width: 100%;
}
.menu-logo-mobile {
  width: 150px;
  height: auto;
  margin-right: auto;
}

.close-icon-container-mobile {
  width: 32px;
  height: 32px;
}
.close-icon-mobile {
  width: 32px;
  height: 32px;
}
.subtitles-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
}

.subtitle-item-mobile {
  cursor: pointer;
  color: #fff;
  font-family: "Author";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 30px;
  letter-spacing: -0.5px;
  height: 10px;
}
.social-media-icon-mobile {
  width: 24px;
  margin-left: 40px;
}

.subtitle-item-mobile:hover {
  color: #f5896b;
}
.social-media-icons-mobile {
  width: 100%;
  margin-top: 30px;
  padding-bottom: 40px;
  margin-left: -40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.menu-ai-toolkit-container {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
}
.menu-ai-toolkit-content {
  display: flex;
  height: 40px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #f5896b;
}

.menu-ai-toolkit-text {
  color: #fff;
  text-align: center;
  font-family: "Mulish";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-left: 9px;
}

@media (max-width: 768px) {
  .menu-container {
    background: linear-gradient(
      180deg,
      #17c1c1 7.49%,
      #154b4b 38.19%,
      #05353a 54.43%
    );
    padding: 0 !important;
  }
  .menu-content {
    display: flex;
    grid-template-columns: none;
    flex-direction: column;
    width: calc(100% - 60px) !important;
    padding: 0 !important;
    margin: 0 30px !important;
  }
  .menu-left-container {
    display: none;
  }
  .menu-right-container {
    display: none;
  }
  .close-icon-container-desktop {
    display: none;
  }
  .menu-background-color-one {
    display: none;
  }
  .menu-background-color-two {
    display: none;
  }
}
@media (max-width: 1449px) {
  .menu-background-color-one {
    display: none;
  }
  .menu-background-color-two {
    display: none;
  }
}

@media (min-width: 768px) {
  .menu-mobile-header {
    display: none;
  }
  .subtitles-mobile {
    display: none;
  }
  .social-media-icons-mobile {
    display: none;
  }
  .menu-background-image {
    display: none;
  }
  .menu-ai-toolkit-container {
    display: none;
  }
}
@media (min-width: 1450px) {
  .menu-background-color-one {
    background: linear-gradient(
      180deg,
      #17c1c1 7.49%,
      #154b4b 38.19%,
      #05353a 54.43%
    ) !important;
    width: 50%;
    height: 100dvh;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
  }
  .menu-background-color-two {
    background-color: white !important;
    width: 50%;
    height: 100dvh;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .subtitle-item {
    font-size: 24px;
    margin-top: -10px;
  }
  .top-right-left-info-item {
    margin-left: 0px;
  }
  .top-right-left-info-text {
    font-size: 14px;
    margin-left: 20px;
  }
  .social-media-item a {
    font-size: 12px;
  }
  .social-media-icon {
    width: 14px;
    height: 14px;
  }
  .close-icon-desktop {
    width: 44px;
    height: 44px;
  }
  .menu-background-image {
    display: none;
  }
}
