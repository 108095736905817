.ambassador-container {
  color: #fff;
  font-family: Mulish, sans-serif;
  padding-top: 120px;
  padding-bottom: 10px;
  position: relative;
}

.ambassador-content {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 3;
}

.ambassador-first-text {
  color: #f5896b;
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0;
  transform: translateY(40px);
}

[data-aos="animation-scale-y"] {
  -webkit-transform: translateY(80px) scaleY(1.4);
  transform: translateY(80px) scaleY(1.4);
  opacity: 0;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
}
[data-aos="animation-scale-y"].aos-animate {
  -webkit-transform: translateY(0) scaleY(1);
  transform: translateY(0) scaleY(1);
  opacity: 1;
}
.ambassador-second-text {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 56px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 72.8px;
  letter-spacing: -1.12px;
  margin-top: 10px;
  opacity: 0;
  transform: translateY(40px);
}
.ambassador-third-text {
  color: #fff;
  font-family: Mulish, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 20px;
  opacity: 0;
  transform: translateY(40px);
}

.ambassador-additional-content {
  margin-top: 40px;
  display: flex;
  align-items: center;
}
.ambassador-custom-button {
  height: 60px;
  width: 200px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #f5896b;
  color: #fff;
  border-width: 0px;
  cursor: pointer;
  border: 1px solid #f5896b;
  position: relative;
  overflow: hidden;
}

.ambassador-custom-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: white;
  color: #f5896b;
  transition: height 0.3s;
}

.ambassador-custom-button:hover::before {
  height: 100%;
}

.ambassador-custom-button span {
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.ambassador-custom-button:hover span {
  filter: brightness(0) saturate(100%) invert(70%) sepia(63%) saturate(1211%)
    hue-rotate(313deg) brightness(97%) contrast(97%);
}

.ambassador-custom-button img {
  width: 20px;
  height: 12px;
  margin-left: 10px;
  vertical-align: middle;
  padding-bottom: 2px !important;
}

.ambassador-custom-button:hover img {
  filter: brightness(0) saturate(100%) invert(70%) sepia(63%) saturate(1211%)
    hue-rotate(313deg) brightness(97%) contrast(97%);
}
.ambassador-additional-icon-and-text {
  display: flex;
  cursor: pointer;
  text-decoration: none;
}

.ambassador-additional-text {
  color: #f5896b;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 24px;
  margin-left: 9px;
}
.ambassador-background-image-one-desktop {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 50%;
  height: 80%;
  z-index: 1;
  max-width: 1440px;
  margin: 0 auto;
}
@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4765.46826171875px;
    stroke-dasharray: 4765.46826171875px;
  }

  100% {
    stroke-dashoffset: 2892.9365234375px;
    stroke-dasharray: 4765.46826171875px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4765.46826171875px;
    stroke-dasharray: 4765.46826171875px;
  }

  100% {
    stroke-dashoffset: 2892.9365234375px;
    stroke-dasharray: 4765.46826171875px;
  }
}

.svg-elem-1 {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

[data-aos="animation-line"] path {
  -webkit-animation: none;
  animation: none;
}
[data-aos="animation-line"].aos-animate path {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

.ambassador-background-image-one-mobile {
  position: absolute;
  top: 0px;
  right: -30px;
  width: 50%;
  height: 100%;
  z-index: 2;
}
.ambassador-background-image-two-mobile {
  position: absolute;
  z-index: 1;
  bottom: -20px;
  left: 0px;
  width: 100%;
  height: 80%;
}
.ambassador-background-image-two-desktop {
  position: absolute;
  z-index: 1;
  bottom: -40px;
  right: 0px;
  width: 55%;
  height: 95%;
}
.ambassador-button-desktop {
  text-decoration: none;
  transform: translateY(80px);
}
.ambassador-button-mobile {
  text-decoration: none;
  transform: translateY(80px);
}

@media (max-width: 768px) {
  .ambassador-container {
    padding: 0 32px;
    padding-top: 64px;
  }
  .ambassador-first-text {
    font-size: 14px;
    line-height: 128.571%;
  }
  .ambassador-second-text {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.4px;
    margin-top: 24px;
  }
  .ambassador-third-text {
    font-size: 16px;
    line-height: 175%;
    margin-top: 40px;
    padding-bottom: 20px;
  }
  .ambassador-additional-content {
    margin-top: 40px;
    flex-direction: column;
    align-items: flex-start;
  }

  .ambassador-custom-button {
    height: 60px;
    width: 200px;
    border-radius: 8px;
  }
  .ambassador-additional-icon-and-text {
    margin-top: 0px;
  }
  .ambassador-additional-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .ambassador-background-image-one-desktop {
    display: none;
  }
  .ambassador-background-image-two-desktop {
    display: none;
  }
  .ambassador-button-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .ambassador-second-text {
    padding-left: 25%;
  }
  .ambassador-first-text {
    padding-left: 25%;
  }
  .ambassador-third-text {
    padding-left: 25%;
    width: 50%;
  }
  .ambassador-additional-content {
    padding-left: 25%;
  }
  .ambassador-custom-button {
    margin-right: 40px;
  }
  .ambassador-background-image-one-mobile {
    display: none;
  }
  .ambassador-background-image-two-mobile {
    display: none;
  }
  .ambassador-button-mobile {
    display: none;
  }
}
