.our-team-container {
  color: #fff;
  font-family: Mulish, sans-serif;
  padding-top: 80px !important;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
.our-team-content {
  position: relative;
  z-index: 2;
}

.our-team-first-text {
  color: #f5896b;
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0;
  transform: translateY(40px);
}

[data-aos="animation-scale-y"] {
  -webkit-transform: translateY(80px) scaleY(1.4);
  transform: translateY(80px) scaleY(1.4);
  opacity: 0;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
}
[data-aos="animation-scale-y"].aos-animate {
  -webkit-transform: translateY(0) scaleY(1);
  transform: translateY(0) scaleY(1);
  opacity: 1;
}
.our-team-second-text {
  color: #fff;
  font-family: "Author", sans-serif;
  font-style: normal;
  font-size: 56px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 72.8px;
  letter-spacing: -1px;
  margin-top: 10px;
  opacity: 0;
  transform: translateY(40px);
}
.arrow-icons-container {
  padding-top: 20px;
  background-color: #05353a !important;
}
.arrow-icon {
  width: 64px;
  height: 64px;
  cursor: pointer;
}
.team-members {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 80px 40px;
  margin-top: 20px;
  padding: 0 12%;
}
.our-team-background-image-two {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 50%;
  height: 40%;
  z-index: 1;
}
.our-team-background-image-one {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 50%;
  height: 35%;
  z-index: 1;
}
@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4145.46826171875px;
    stroke-dasharray: 4145.46826171875px;
  }
  100% {
    stroke-dashoffset: 2220.9365234375px;
    stroke-dasharray: 4145.46826171875px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 4145.46826171875px;
    stroke-dasharray: 4145.46826171875px;
  }
  100% {
    stroke-dashoffset: 2220.9365234375px;
    stroke-dasharray: 4145.46826171875px;
  }
}

.svg-elem-1 {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

[data-aos="animation-line"] path {
  -webkit-animation: none;
  animation: none;
}
[data-aos="animation-line"].aos-animate path {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}
.one-team-member {
  transform: translateY(40px);
}

@media (max-width: 768px) {
  .our-team-container {
    padding: 0 32px;
  }
  .our-team-first-text {
    font-size: 14px;
    line-height: 128.571%;
  }
  .our-team-second-text {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.4px;
    margin-top: 24px;
  }
  .team-members {
    display: flex;
    overflow-x: scroll;
    gap: 80px;
    padding: 20px 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-snap-type: x mandatory;
  }
  #team-members-container {
    scroll-snap-type: x mandatory;
  }
  .team-members::-webkit-scrollbar {
    display: none;
  }
  .one-team-member {
    flex: 0 0 100%;
    scroll-snap-align: start;
  }
  .our-team-background-image-one {
    display: none;
  }
  .our-team-background-image-two {
    display: none;
  }
}

@media (min-width: 768px) {
  .our-team-second-text {
    padding-left: 25%;
  }
  .our-team-first-text {
    padding-left: 25%;
  }
  .arrow-icons-container {
    display: none;
  }
}
