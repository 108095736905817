.one-input-field-container {
  width: 100%;
  padding: 0 40px;
  margin-top: 10px;
}

.one-input-field-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  border: 1px solid var(--Deviders---Grey, #d8d8d8);
  background: #fff;
  height: 60px;
  padding: 0 20px;
}

.one-input-icon {
  width: 20px;
  height: 20px;
}

.one-input-vertical-line {
  width: 1px;
  height: 20px;
  background: rgba(241, 137, 111, 0.44);
}

.one-input-custom-input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  background: transparent;
  color: #2d2d2d;
}
.one-input-file-label {
  cursor: pointer;
  color: #f5896b;
  font-family: Author;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: #fff;
}

.one-input-file-label input[type="file"] {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.input-error-text {
  color: red;
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  padding-top: 5px;
  margin: 0;
  padding-left: 5px;
}

@media (max-width: 768px) {
  .one-input-field-container {
    padding: 0px;
  }
  .input-error-text {
    font-size: 13px;
    font-weight: 400;
  }
  .one-input-field-wrapper {
    border: 1.5px solid var(--Deviders---Grey, #d8d8d8);
  }
}
