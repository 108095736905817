.who-we-are-container {
  color: #fff;
  font-family: Mulish, sans-serif;
  padding-top: 40px;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
.who-we-are-content {
  position: relative;
  z-index: 2;
}

.who-we-are-first-text {
  color: #f5896b;
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0;
  transform: translateY(80px);
}

[data-aos="animation-scale-y"] {
  -webkit-transform: translateY(80px) scaleY(1.4);
  transform: translateY(80px) scaleY(1.4);
  opacity: 0;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
}
[data-aos="animation-scale-y"].aos-animate {
  -webkit-transform: translateY(0) scaleY(1);
  transform: translateY(0) scaleY(1);
  opacity: 1;
}
.who-we-are-second-text {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 56px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 72.8px;
  letter-spacing: -1.12px;
  margin-top: 10px;
  opacity: 0;
  transform: translateY(80px);
}
.who-we-are-background-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50%;
  height: 35%;
  z-index: 1;
}
@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 3835.46826171875px;
    stroke-dasharray: 3835.46826171875px;
  }

  100% {
    stroke-dashoffset: 1970.9365234375px;
    stroke-dasharray: 3835.46826171875px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 3835.46826171875px;
    stroke-dasharray: 3835.46826171875px;
  }

  100% {
    stroke-dashoffset: 1970.9365234375px;
    stroke-dasharray: 3835.46826171875px;
  }
}

.svg-elem-1 {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

[data-aos="animation-line"] path {
  -webkit-animation: none;
  animation: none;
}
[data-aos="animation-line"].aos-animate path {
  -webkit-animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
  animation:
    animate-svg-stroke-1 3s linear 0s both,
    animate-svg-fill-1 0.7s linear 1.3s both;
}

.download-manifesto-container {
  display: flex;
  padding: 18.025px 56.328px;
  justify-content: center;
  align-items: center;
  gap: 9.013px;
  border-radius: 22.531px;
  background: #f5896b;
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.038px;
  margin-top: 98px;
  cursor: pointer;
  border: 1px solid #f5896b;
  transition:
    color 0.3s,
    border-color 0.3s;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.download-manifesto-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: white;
  transition: height 0.3s;
}
.download-manifesto-container:hover::before {
  height: 100%;
}
.download-manifesto-container span {
  position: relative;
  z-index: 1;
}
.download-manifesto-container:hover span {
  color: #f5896b;
}
.who-we-are-arrows {
  display: flex;
  align-items: center;
}
.who-we-are-arrows-container-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 64px;
  margin-left: -10px;
}
.who-we-are-arrows-container-desktop {
  position: absolute;
  right: 5%;
  top: 30px;
}
.who-we-are-title-and-arrow {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;
}

.who-we-are-title-mobile {
  color: #fff;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-right: 30px;
}

.who-we-are-dropdown-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
}
.who-we-are-main-content-mobile {
  margin-top: 40px;
}
.who-we-are-main-content-text {
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 0px !important;
}
.core-values-list {
  margin-top: 40px;
  color: #fff;
  font-family: "Author", sans-serif;
  font-size: 16px;
  line-height: 28px;
}
.core-values-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  padding-left: 16px;
}
.core-values-dot {
  font-weight: 600;
  margin-right: 8px;
  margin-left: -14px;
}
.core-values-title {
  font-weight: 600;
  font-family: "Mulish-Bold";
}
.core-values-text {
  text-align: left;
  overflow-wrap: break-word;
  margin-left: 6px;
  font-weight: 400;
}
.dropdown-menu {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: auto;
  min-width: 70px;
  padding: 20px;
  padding-top: 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.06);
  z-index: 10;
}

.dropdown-item {
  cursor: pointer;
  color: #2d2d2d;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-top: 15px;
}

.selected-dropdown-item {
  color: #f5896b;
}
.who-we-are-main-content-first-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  margin-bottom: 70px;
}

.who-we-are-main-content-first-component-line-and-text {
  display: flex;
  align-items: center;
  margin-top: 70px;
  padding-left: 20%;
  position: relative;
}

.who-we-are-main-content-first-component-line {
  width: 24px;
  height: 4px;
  border-radius: 1px;
  background: #565656;
  position: relative;
}

.who-we-are-main-content-first-component-line-selected {
  background: #fff;
  animation: slideLine 0.5s linear;
}
@keyframes slideLine {
  from {
    width: 0;
    left: 10px;
  }
  to {
    width: 24px;
    left: 0;
  }
}

.who-we-are-main-content-first-component-text {
  color: #8b8b8b;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 12px;
  cursor: pointer;
}

.who-we-are-main-content-first-component-text-selected {
  color: #fff;
}

.who-we-are-main-content-desktop {
  margin-top: 80px;
  display: flex;
  transform: translateY(80px);
}

.who-we-are-main-content-second-component-scroll-wrapper {
  width: 75%;
  position: relative;
  overflow: visible !important;
}

.who-we-are-main-content-second-component {
  width: 100%;
  display: flex;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  scroll-snap-type: x mandatory;
  gap: 100px;
  overflow: hidden !important;
}

.who-we-are-main-content-second-component-content {
  width: 100%;
  height: 500px;
  margin-top: 34px;
  margin-bottom: 34px;
  background-color: #fff;
  border-radius: 32px;
  position: relative;
  padding: 40px;
  box-sizing: border-box;
  flex: 0 0 100%;
}
.manifesto-shape-container {
  position: absolute;
  top: -34px;
  bottom: -34px;
  right: 0;
  width: 50%;
  height: 568px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.manifesto-shape {
  width: 100%;
  height: 100%;
}
.selected-item-image {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
}

.who-we-are-main-content-first-component-activity-line {
  z-index: 4;
  position: absolute;
  right: -20px;
}
.who-we-are-main-content-second-component-title {
  margin-top: 28px;
  color: #2d2d2d;
  font-family: "Author", sans-serif;
  font-size: 40px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 130%;
  letter-spacing: -0.8px;
}

.who-we-are-main-content-second-component-text {
  color: #2d2d2d;
  font-family: "Mulish";
  font-size: 21px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 20px;
  width: 50%;
}

@-webkit-keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 52.26548245743669px;
    stroke-dasharray: 52.26548245743669px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 52.26548245743669px;
  }
}

@keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 52.26548245743669px;
    stroke-dasharray: 52.26548245743669px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 52.26548245743669px;
  }
}

@-webkit-keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(245, 137, 107);
  }
}

@keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(245, 137, 107);
  }
}

.svg-elem-2 {
  -webkit-animation:
    animate-svg-stroke-2 0.5s linear 0s both,
    animate-svg-fill-2 0.3s linear 0.6s both;
  animation:
    animate-svg-stroke-2 0.5s linear 0s both,
    animate-svg-fill-2 0.3s linear 0.6s both;
}

@-webkit-keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 27.132741228718345px;
    stroke-dasharray: 27.132741228718345px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 27.132741228718345px;
  }
}

@keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 27.132741228718345px;
    stroke-dasharray: 27.132741228718345px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 27.132741228718345px;
  }
}

@-webkit-keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(245, 137, 107);
  }
}

@keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(245, 137, 107);
  }
}

.svg-elem-3 {
  -webkit-animation:
    animate-svg-stroke-3 0.5s linear 0s both,
    animate-svg-fill-3 0.3s linear 0.3s both;
  animation:
    animate-svg-stroke-3 0.5s linear 0s both,
    animate-svg-fill-3 0.3s linear 0.3s both;
}

@-webkit-keyframes animate-svg-stroke-4 {
  0% {
    stroke-dashoffset: 104.33302307128906px;
    stroke-dasharray: 104.33302307128906px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 104.33302307128906px;
  }
}

@keyframes animate-svg-stroke-4 {
  0% {
    stroke-dashoffset: 104.33302307128906px;
    stroke-dasharray: 104.33302307128906px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 104.33302307128906px;
  }
}

.svg-elem-4 {
  -webkit-animation:
    animate-svg-stroke-4 0.5s linear 0s both,
    animate-svg-fill-4 0.3s linear 1.4s both;
  animation:
    animate-svg-stroke-4 0.5s linear 0s both,
    animate-svg-fill-4 0.3s linear 1.4s both;
}

@-webkit-keyframes animate-svg-stroke-5 {
  0% {
    stroke-dashoffset: 17.707963267948966px;
    stroke-dasharray: 17.707963267948966px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 17.707963267948966px;
  }
}

@keyframes animate-svg-stroke-5 {
  0% {
    stroke-dashoffset: 17.707963267948966px;
    stroke-dasharray: 17.707963267948966px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 17.707963267948966px;
  }
}

@-webkit-keyframes animate-svg-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(245, 137, 107);
  }
}

@keyframes animate-svg-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(245, 137, 107);
  }
}

.svg-elem-5 {
  -webkit-animation:
    animate-svg-stroke-5 0.5s linear 0s both,
    animate-svg-fill-5 0.3s linear 1.8s both;
  animation:
    animate-svg-stroke-5 0.5s linear 0s both,
    animate-svg-fill-5 0.3s linear 1.8s both;
}

@-webkit-keyframes animate-svg-stroke-6 {
  0% {
    stroke-dashoffset: 254px;
    stroke-dasharray: 254px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 254px;
  }
}

@keyframes animate-svg-stroke-6 {
  0% {
    stroke-dashoffset: 254px;
    stroke-dasharray: 254px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 254px;
  }
}

@-webkit-keyframes animate-svg-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-6 {
  -webkit-animation:
    animate-svg-stroke-6 0.5s linear 0s both,
    animate-svg-fill-6 0.3s linear 2.2s both;
  animation:
    animate-svg-stroke-6 0.5s linear 0s both,
    animate-svg-fill-6 0.3s linear 2.2s both;
}

@-webkit-keyframes animate-svg-stroke-7 {
  0% {
    stroke-dashoffset: 66px;
    stroke-dasharray: 66px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 66px;
  }
}

@keyframes animate-svg-stroke-7 {
  0% {
    stroke-dashoffset: 66px;
    stroke-dasharray: 66px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 66px;
  }
}

@-webkit-keyframes animate-svg-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-7 {
  -webkit-animation:
    animate-svg-stroke-7 0.5s linear 0s both,
    animate-svg-fill-7 0.3s linear 2.6s both;
  animation:
    animate-svg-stroke-7 0.5s linear 0s both,
    animate-svg-fill-7 0.3s linear 2.6s both;
}

@media (max-width: 768px) {
  .who-we-are-arrow-icons-container {
    padding-top: 0px !important;
    margin-left: -10px !important;
    background-color: #05353a !important;
  }
  .who-we-are-arrow-icon {
    width: 64px;
    height: 64px;
    cursor: pointer;
  }
  .who-we-are-container {
    padding: 0 32px;
    padding-top: 40px;
  }
  .who-we-are-first-text {
    font-size: 14px;
    line-height: 128.571%;
  }
  .who-we-are-second-text {
    display: none;
  }
  .who-we-are-second-text-mobile {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.4px;
    margin-top: 24px;
    color: #fff;
    font-family: "Author", sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    opacity: 0;
    transform: translateY(80px);
  }
  .who-we-are-background-image {
    display: none;
  }
  .core-values-title {
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
  }
  .download-manifesto-container {
    border-radius: 8px;
    background: #f5896b;
    height: 56px;
    padding: 0 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    width: fit-content;
    margin-top: 50px;
    transform: translateY(80px);
  }
  .who-we-are-arrows-container-desktop {
    display: none;
  }
  .who-we-are-main-content-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .who-we-are-arrow-icons-container {
    display: none;
  }
  .who-we-are-second-text-mobile {
    display: none;
  }
  .who-we-are-second-text {
    padding-left: 25%;
  }
  .who-we-are-first-text {
    padding-left: 25%;
  }
  .who-we-are-content {
    padding-right: 5%;
  }
  .download-manifesto-container {
    width: fit-content;
    padding: 18.025px 56.328px;
    margin-left: auto;
  }
  .who-we-are-arrows-container-mobile {
    display: none;
  }
  .who-we-are-main-content-mobile {
    display: none;
  }
  .core-values-list {
    margin-top: 0px;
    color: #2d2d2d;
    font-family: Mulish;
    font-size: 21px;
    line-height: 130%;
  }
}

@media (min-width: 768px) and (max-width: 1350px) {
  .who-we-are-main-content-first-component-text {
    font-size: 18px;
  }
  .who-we-are-main-content-first-component-line {
    width: 16px;
  }
  .who-we-are-main-content-first-component-line-selected {
    background: #fff;
    animation: slideLine 0.5s linear;
  }
  @keyframes slideLine {
    from {
      width: 0;
      left: 8px;
    }
    to {
      width: 16px;
      left: 0;
    }
  }
  .who-we-are-main-content-first-component-activity-line {
    transform: scale(0.8);
    right: -40px;
  }
  .who-we-are-main-content-second-component-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.8px;
  }
  .who-we-are-main-content-second-component-text {
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 130%;
  }
  .core-values-list {
    margin-top: 0px;
    font-size: 16px;
    line-height: 130%;
  }
  .who-we-are-main-content-first-component-line-and-text {
    padding-left: 10%;
  }
}

@media (min-width: 768px) and (max-width: 1050px) {
  .who-we-are-main-content-first-component-activity-line {
    transform: scale(0.6);
    right: -40px;
  }
}
